import Ranebennur1 from "../image/Ranebennur1.jpeg";
import Ranebennur2 from "../image/Ranebennur2.jpeg";
import Ranebennur3 from "../image/Ranebennur3.jpeg";
import Ranebennur4 from "../image/Ranebennur4.jpeg";
import Ranebennur5 from "../image/Ranebennur5.jpeg";
import Ranebennur6 from "../image/Ranebennur6.jpeg";
import Ranebennur7 from "../image/Ranebennur7.jpeg";
import Ranebennur8 from "../image/Ranebennur8.jpeg";
import Ranebennur9 from "../image/Ranebennur9.jpeg";
import Kadiri1 from "../image/Kadiri1.jpeg";
import Kadiri2 from "../image/Kadiri2.jpeg";
import Kadiri3 from "../image/Kadiri3.jpeg";
import Kadiri4 from "../image/Kadiri4.jpeg";
import Kadiri5 from "../image/Kadiri5.jpeg";
import Kadiri6 from "../image/Kadiri6.jpeg";
import Kadiri7 from "../image/Kadiri7.jpeg";
import Kadiri8 from "../image/Kadiri8.jpeg";
import Kadiri9 from "../image/Kadiri9.jpeg";
// import Badvel1 from "../image/Badvel1.jpeg";
// import Badvel2 from "../image/Badvel2.jpeg";
// import Badvel3 from "../image/Badvel3.jpeg";
// import Badvel4 from "../image/Badvel4.jpeg";
import Ananthpur1 from "../image/Ananthpur1.jpeg";
// import Ananthpur2 from "../image/Ananthpur2.jpeg";
// import Ananthpur3 from "../image/Ananthpur3.jpeg";
// import Ananthpur4 from "../image/Ananthpur4.jpeg";
import Ananthpur5 from "../image/Ananthpur5.jpeg";
import Ananthpur7 from "../image/Ananthpur7.jpeg";
import Ananthpur8 from "../image/Ananthpur8.jpeg";

import Periyapatna1 from "../image/Periyapatna1.jpeg";
import Periyapatna2 from "../image/Periyapatna2.jpeg";
import Periyapatna3 from "../image/Periyapatna3.jpeg";
import Periyapatna4 from "../image/Periyapatna4.jpeg";
import Periyapatna5 from "../image/Periyapatna5.jpeg";
import Rayachuti1 from "../image/Rayachuti1.jpeg";
import Rayachuti2 from "../image/Rayachuti2.jpeg";
import Rayachuti3 from "../image/Rayachuti3.jpeg";
import Rayachuti4 from "../image/Rayachuti4.jpeg";
import Rayachuti5 from "../image/Rayachuti5.jpeg";
import Rayachuti6 from "../image/Rayachuti6.jpeg";
import Rayachuti7 from "../image/Rayachuti7.jpeg";
import Rayachuti8 from "../image/Rayachuti8.jpeg";
import Repalli1 from "../image/Repalli1.jpeg";
import Repalli2 from "../image/Repalli2.jpeg";
import Repalli3 from "../image/Repalli3.jpeg";
// import Vempalli1 from "../image/Vempalli1.jpeg";
import Vempalli2 from "../image/Vempalli2.jpeg";
import Vempalli3 from "../image/Vempalli3.jpeg";
import Vempalli4 from "../image/Vempalli4.jpeg";
// import Vempalli5 from "../image/Vempalli5.jpeg";
import Vinjumur1 from "../image/Vinjumur1.jpeg";
import Vinjumur2 from "../image/Vinjumur2.jpeg";
import Vinjumur3 from "../image/Vinjumur3.jpeg";
import Vinjumur4 from "../image/Vinjumur4.jpeg";
import Vinjumur5 from "../image/Vinjumur5.jpeg";
import HonnaliStore1 from "../image/HonnaliStore1.jpeg";
import HonnaliStore2 from "../image/HonnaliStore2.jpeg";
import HonnaliStore3 from "../image/HonnaliStore3.jpeg";
import HonnaliStore4 from "../image/HonnaliStore4.jpeg";
import HonnaliStore5 from "../image/HonnaliStore5.jpeg";
import HonnaliStore6 from "../image/HonnaliStore6.jpeg";
import HandPost1 from "../image/HandPost1.jpeg";
import HandPost2 from "../image/HandPost2.jpeg";
import HandPost3 from "../image/HandPost3.jpeg";
import Nuziveedu1 from "../image/Nuziveedu1.jpeg";
import Nuziveedu2 from "../image/Nuziveedu2.jpeg";
import Nuziveedu3 from "../image/Nuziveedu3.jpeg";
import Nuziveedu4 from "../image/Nuziveedu4.jpeg";
import Nuziveedu5 from "../image/Nuziveedu5.jpeg";
import Nuziveedu6 from "../image/Nuziveedu6.jpeg";
import Nuziveedu7 from "../image/Nuziveedu7.jpeg";
import Nuziveedu8 from "../image/Nuziveedu8.jpeg";
import Kothakota1 from "../image/Kothakota1.jpeg";
import Kothakota2 from "../image/Kothakota2.jpeg";
import Kothakota3 from "../image/Kothakota3.jpeg";
import Kothakota4 from "../image/Kothakota4.jpeg";
import Kothakota5 from "../image/Kothakota5.jpeg";
import Kustagi1 from "../image/Kustagi1.jpeg";
import Kustagi2 from "../image/Kustagi2.jpeg";
import Kustagi3 from "../image/Kustagi3.jpeg";
import Kustagi4 from "../image/Kustagi4.jpeg";
import Kustagi5 from "../image/Kustagi5.jpeg";
import Kustagi6 from "../image/Kustagi6.jpeg";
import Kustagi7 from "../image/Kustagi7.jpeg";
import Kustagi8 from "../image/Kustagi8.jpeg";
import Davangere1 from "../image/Davangere1.jpeg";
import Davangere2 from "../image/Davangere2.jpeg";
import Davangere3 from "../image/Davangere3.jpeg";
import Davangere4 from "../image/Davangere4.jpeg";
import Davangere5 from "../image/Davangere5.jpeg";
import Davangere6 from "../image/Davangere6.jpeg";
import Davangere7 from "../image/Davangere7.jpeg";
import Davangere8 from "../image/Davangere8.jpeg";
import Davangere9 from "../image/Davangere9.jpeg";
import Davangere10 from "../image/Davangere10.jpeg";
import Palamneru1 from "../image/Palamneru1.jpeg";
import Palamneru2 from "../image/Palamneru2.jpeg";
import Palamneru3 from "../image/Palamneru3.jpeg";
import Palamneru4 from "../image/Palamneru4.jpeg";
import Palamneru5 from "../image/Palamneru5.jpeg";
import Palamneru6 from "../image/Palamneru6.jpeg";
import Kalkiri1 from "../image/Kalkiri1.jpeg";
import Kalkiri2 from "../image/Kalkiri2.jpeg";
import Kalkiri3 from "../image/Kalkiri3.jpeg";
import Vijayawada1 from "../image/Vijayawada1.jpeg";
import Vijayawada2 from "../image/Vijayawada2.jpeg";
import Vijayawada3 from "../image/Vijayawada3.jpeg";
import Vijayawada4 from "../image/Vijayawada4.jpeg";
import Vijayawada5 from "../image/Vijayawada5.jpeg";
import Galiveedu1 from "../image/Galiveedu1.jpeg"
import Galiveedu2 from "../image/Galiveedu2.jpeg"
import Galiveedu3 from "../image/Galiveedu3.jpeg"
import Galiveedu4 from "../image/Galiveedu4.jpeg"
import Galiveedu5 from "../image/Galiveedu5.jpeg"
import Gorantala1 from "../image/Gorantala1.jpeg"
import Gorantala2 from "../image/Gorantala2.jpeg"
import Gorantala3 from "../image/Gorantala3.jpeg"
import Gorantala4 from "../image/Gorantala4.jpeg"
import KrPuram1 from "../image/krpuram1.jpeg"
import KrPuram2 from "../image/krpuram2.jpeg"
import KrPuram3 from "../image/krpuram3.jpeg"
import KrPuram4 from "../image/krpuram4.jpeg"
import KrPuram5 from "../image/krpuram5.jpeg"




export const store = [
  // Rannebennur
  {
    id: 1,
    location: "Rannebennur",
    address: "Beside Canara Bank ,Railway Station Road , Ranebennur -581115",
    PinCode: "581115",
    map: "",
    category:"Karnataka",
    number1: 9364099708,
    number2: "",
    image: [
      {
        id: 1,
        url: Ranebennur1,
      },
      {
        id: 2,
        url: Ranebennur2,
      },
      {
        id: 3,
        url: Ranebennur3,
      },
      {
        id: 4,
        url: Ranebennur4,
      },
      {
        id: 5,
        url: Ranebennur5,
      },
      {
        id: 6,
        url: Ranebennur6,
      },
      {
        id: 7,
        url: Ranebennur7,
      },
      {
        id: 8,
        url: Ranebennur8,
      },
      {
        id: 9,
        url: Ranebennur9,
      },
    ],
  },
  // Honnali
  {
    id: 2,
    location: "Honnali",
    address:
      "Pattana Shetty Complex ,T.B . Circle ,Honnali-577217 . Dist : Davangere",
    PinCode: "577217",
    map: "https://maps.app.goo.gl/Zhnjd88hqRvKyfqn6",
    category:"Karnataka",
    number1: 7483890855,
    number2: "",
    image: [
      {
        id: 1,
        url: HonnaliStore6,
      },
      {
        id: 2,
        url: HonnaliStore1,
      },
      {
        id: 3,
        url: HonnaliStore2,
      },
      {
        id: 4,
        url: HonnaliStore3,
      },
      {
        id: 5,
        url: HonnaliStore4,
      },
      {
        id: 6,
        url: HonnaliStore5,
      },
    ],
  },
  // Kustagi
  {
    id: 3,
    location: "Kustagi",
    address: "Shree Sai Krishna Complex Opp KSRTC Bus Stand , Kushtagi -583277",
    PinCode: "583277",
    map: "https://maps.app.goo.gl/mVPja8wK4wFtm8TT6",
    category:"Karnataka",
    number1: 9364099709,
    number2: "",
    image: [
      {
        id: 1,
        url: Kustagi1,
      },
      {
        id: 2,
        url: Kustagi2,
      },
      {
        id: 3,
        url: Kustagi3,
      },
      {
        id: 4,
        url: Kustagi4,
      },
      {
        id: 5,
        url: Kustagi5,
      },
      {
        id: 6,
        url: Kustagi6,
      },
      {
        id: 7,
        url: Kustagi7,
      },
      {
        id: 8,
        url: Kustagi8,
      },
    ],
  },
  // Davanagere
  {
    id: 4,
    location: "Davanagere",
    address: "#294, Below Bheema lodge , Ashoka Road , Davangere -577002",
    PinCode: "577002",
    map: "https://maps.app.goo.gl/Bq2Jr7nRXD2E8KwCA",
    category:"Karnataka",
    number1: 9364099711,
    number2: "",
    image: [
      {
        id: 1,
        url: Davangere1,
      },
      {
        id: 2,
        url: Davangere2,
      },
      {
        id: 3,
        url: Davangere3,
      },
      {
        id: 4,
        url: Davangere4,
      },
      {
        id: 5,
        url: Davangere5,
      },
      {
        id: 6,
        url: Davangere6,
      },
      {
        id: 7,
        url: Davangere7,
      },
      {
        id: 8,
        url: Davangere8,
      },
      {
        id: 9,
        url: Davangere9,
      },
      {
        id: 10,
        url: Davangere10,
      },
    ],
  },
  // Hand Post
  {
    id: 5,
    location: "Hand post",
    address:
      "#317/A Near federal Bank , M m Road R P Circle Handpost HD Kote Road Mysore ",
    PinCode: "571125",
    map: "https://maps.app.goo.gl/d2Fesvwzvo7f3q3WA",
    category:"Karnataka",
    number1: 9364099693,
    number2: "",
    image: [
      {
        id: 1,
        url: HandPost2,
      },
      {
        id: 2,
        url: HandPost1,
      },
      {
        id: 3,
        url: HandPost3,
      },
    ],
  },
  // Nuziveedu
  {
    id: 6,
    location: "Nuziveedu",
    address:
      "17-1,OOP Cenara Bank ,Chinna Gandhi Bomma Centre ,Human Junction Road , Nuzvidu .",
    PinCode: "521201",
    map: "https://maps.app.goo.gl/ji6TKxX3ZVqbp6AS9",
    category: "Andhra" ,
    number1: 9364099679,
    number2:"",
    image: [
      {
        id: 1,
        url: Nuziveedu1,
      },
      {
        id: 2,
        url: Nuziveedu2,
      },
      {
        id: 3,
        url: Nuziveedu3,
      },
      {
        id: 4,
        url: Nuziveedu4,
      },
      {
        id: 5,
        url: Nuziveedu5,
      },
      {
        id: 6,
        url: Nuziveedu6,
      },
      {
        id: 7,
        url: Nuziveedu7,
      },
      {
        id: 8,
        url: Nuziveedu8,
      },
    ],
  },
  // Repalle
  {
    id: 7,
    location: "Repalle",
    address:
      "10-14-123 GKR Complex ,Municipal Office Road  opp Sekhar Book Stal Railpeta ,Repalli.",
    PinCode: "522265",
    map: "https://maps.app.goo.gl/4fCCC6WF85uLJcETA",
    category: "Andhra" ,
    number1: 9364099680,
    number2: "",
    image: [
      {
        id: 1,
        url: Repalli1,
      },
      {
        id: 2,
        url: Repalli2,
      },
      {
        id: 3,
        url: Repalli3,
      },
    ],
  },
  // Gorantala
  {
    id: 8,
    location: "Gorantala",
    address: "D.No.3/498, Near Market Street, GORANTLA-515231",
    PinCode: "515231",
    map: "https://maps.app.goo.gl/yRGg8pP3ZnhNThSS7",
    category: "Andhra" ,
    number1: 9364099705,
    number2: "",
    image: [
      {
        id: 1,
        url: Gorantala1,
      },
      {
        id: 2,
        url: Gorantala2,
      },
      {
        id: 3,
        url: Gorantala3,
      },
      {
        id: 4,
        url: Gorantala4,
      },
    ],
  },
  // kalyandurgam
  {
    id: 9,
    location: "kalyandurgam",
    address:
      "#8-90 VTS COMPLEX Opp.Reliance Trends, Hindupur Road , KALYANDURGA-515761",
    PinCode: "515761",
    map: "https://maps.app.goo.gl/NEpWBpgMFbWtJEc36",
    category: "Andhra" ,
    number1:  9364099703,
    number2: "",
  },
  // Ananthpur
  {
    id: 10,
    location: "Ananthpur",
    address:
      "D.No. 15-68 Gayathri Complex Opp Vasan Eye Care Raju Road ANANTAPUR-515001",
    PinCode: "515001",
    map: "https://maps.app.goo.gl/HcUC23Js6Xc8xmLP9",
    category: "Andhra" ,
    number1: 9364099701,
    number2:"",
    image: [
      {
        id: 1,
        url: Ananthpur5,
      },
      {
        id: 2,
        url: Ananthpur1,
      },
      {
        id: 3,
        url: Ananthpur7,
      },
      {
        id: 4,
        url: Ananthpur8,
      },
    ],
  },
  // Kadiri
  {
    id: 11,
    location: "Kadiri",
    address:
      "# 9/245,Madanapalli Bypass Road, Opp. Radhika Theatre, KADIRI-515591",
    PinCode: "515591",
    map: "https://maps.app.goo.gl/nkpGZrHu3tGZA9dE8",
    category: "Andhra" ,
    number1: 9364099707,
    number2: "",
    image: [
      {
        id: 1,
        url: Kadiri1,
      },
      {
        id: 2,
        url: Kadiri2,
      },
      {
        id: 3,
        url: Kadiri3,
      },
      {
        id: 4,
        url: Kadiri4,
      },
      {
        id: 5,
        url: Kadiri5,
      },
      {
        id: 6,
        url: Kadiri6,
      },
      {
        id: 7,
        url: Kadiri7,
      },
      {
        id: 8,
        url: Kadiri8,
      },
      {
        id: 9,
        url: Kadiri9,
      },
    ],
  },
  // Palamneru
  {
    id: 12,
    location: "Palamneru",
    address:
      "#12-42/1,Badri Complex, Javilli Street, Near Manjunatha Theater, PALAMANER-517408",
    PinCode: "517408",
    map: "https://maps.app.goo.gl/mouMt97WdNGEDB6y9",
    category: "Andhra" ,
    number1: 9364099704,
    number2: "",
    image: [
      {
        id: 1,
        url: Palamneru1,
      },
      {
        id: 2,
        url: Palamneru2,
      },
      {
        id: 3,
        url: Palamneru3,
      },
      {
        id: 4,
        url: Palamneru4,
      },
      {
        id: 5,
        url: Palamneru5,
      },
      {
        id: 6,
        url: Palamneru6,
      },
    ],
  },
  // Galiveedu
  {
    id: 13,
    location: "Galiveedu",
    address: "D.No.5/478, Near Busstand Rayachoti Road, GALIVEEDU-516267",
    PinCode: "516267",
    map: "https://maps.app.goo.gl/ur7cVwVe2GNvXbnB7",
    category: "Andhra" ,
    number1: 9364099706,
    number2: "",
    image: [
      {
        id: 1,
        url: Galiveedu1,
      },
      {
        id: 2,
        url: Galiveedu2,
      },
      {
        id: 3,
        url: Galiveedu3,
      },
      {
        id: 4,
        url: Galiveedu4,
      },
      {
        id: 5,
        url: Galiveedu5,
      },
    ],
  },
  // Vijayawada
  {
    id: 14,
    location: "Vijayawada",
    address:
      "Resident of 2nd floor of Door No.29-37-163,Eluru Road Governopet,VIJAYAWADA",
    PinCode: "520002",
    map: "https://maps.app.goo.gl/n1KknTAqw9uBQdRc9",
    category: "Andhra" ,
    number1: 9364099696,
    number2:"",
    image: [
      {
        id: 1,
        url: Vijayawada1,
      },
      {
        id: 2,
        url: Vijayawada2,
      },
      {
        id: 3,
        url: Vijayawada3,
      },
      {
        id: 4,
        url: Vijayawada4,
      },
      {
        id: 5,
        url: Vijayawada5,
      },
    ],
  },
  // Periyapatna
  {
    id: 15,
    location: "Periyapatna",
    address: "No-1524 Yajamana Complex, Santhepete Road,PERIYAPATNA-571107",
    PinCode: "571107",
    map: "https://maps.app.goo.gl/e4rH1joVmrFMHS7U9",
    category:"Karnataka",
    number1: 9364099592,
    number2:"",
    image: [
      {
        id: 1,
        url: Periyapatna2,
      },
      {
        id: 2,
        url: Periyapatna1,
      },
      {
        id: 3,
        url: Periyapatna3,
      },
      {
        id: 4,
        url: Periyapatna4,
      },
      {
        id: 5,
        url: Periyapatna5,
      },
    ],
  },
  // Vempalli
  {
    id: 16,
    location: "Vempalli",
    address: "Ground floor,No 6/185,Patha Peta,Opp BSNL Office,Vempalli-516329",
    PinCode: "516329",
    map: "https://maps.app.goo.gl/1nhQ131yEdCebjV19",
    category: "Andhra" ,
    number1: 9364099695,
    number2: "",
    image: [
      {
        id: 1,
        url: Vempalli4,
      },
      {
        id: 2,
        url: Vempalli2,
      },
      {
        id: 3,
        url: Vempalli3,
      },
    ],
  },
  // Vinjamur
  {
    id: 17,
    location: "Vinjamur",
    address: "Ground Floor,No.8-2,MainRoad,Beside Union Bank,Vinjumur-524228",
    PinCode: "524228",
    map: "https://maps.app.goo.gl/8hGUhj1kb77jj3E99",
    category: "Andhra" ,
    number1: 9364099694,
    number2: "",
    image: [
      {
        id: 1,
        url: Vinjumur1,
      },
      {
        id: 2,
        url: Vinjumur2,
      },
      {
        id: 3,
        url: Vinjumur3,
      },
      {
        id: 4,
        url: Vinjumur4,
      },
      {
        id: 5,
        url: Vinjumur5,
      },
    ],
  },
  // Rayachoti
  {
    id: 18,
    location: "Rayachoti",
    address:
      "Ground Floor, No 61-130,hittor Road,Beside Bombay Loadge,Rayachoti -516269",
    PinCode: "516269",
    map: "https://maps.app.goo.gl/tMW9PSut5kBkKVEN6",
    category: "Andhra" ,
    number1: 9364099681,
    number2:"",
    image: [
      {
        id: 1,
        url: Rayachuti1,
      },
      {
        id: 2,
        url: Rayachuti2,
      },
      {
        id: 3,
        url: Rayachuti3,
      },
      {
        id: 4,
        url: Rayachuti4,
      },
      {
        id: 5,
        url: Rayachuti5,
      },
      {
        id: 6,
        url: Rayachuti6,
      },
      {
        id: 7,
        url: Rayachuti7,
      },

      {
        id: 8,
        url: Rayachuti8,
      },
    ],
  },
  // Kalkiri
  {
    id: 19,
    location: "Kalkiri",
    address: "1st Floor,No 6-649,T B Road,Near RTC Busstand,Kalkiri-517234",
    PinCode: "517234",
    map: "https://maps.app.goo.gl/TgXhGZ7eSYcRhujZ7",
    category: "Andhra" ,
    number1: 9364099677,
    number2: "",
    image: [
      {
        id: 1,
        url: Kalkiri1,
      },
      {
        id: 2,
        url: Kalkiri2,
      },
      {
        id: 3,
        url: Kalkiri3,
      },
    ],
  },
  // B.Kothakota
  {
    id: 20,
    location: "B.Kothakota",
    address:
      "1st Floor,No 10-169,Banglore rad,Beside Apollo Pharmacy,b Kothakota-517370",
    PinCode: "517370",
    map: "https://maps.app.goo.gl/DCCijZbdJveBHetB6",
    category: "Andhra" ,
    number1: 9364099676,
    number2: "",
    image: [
      {
        id: 1,
        url: Kothakota2,
      },
      {
        id: 2,
        url: Kothakota1,
      },
      {
        id: 3,
        url: Kothakota3,
      },
      {
        id: 4,
        url: Kothakota4,
      },
      {
        id: 5,
        url: Kothakota5,
      },
    ],
  },
  // Badvel
  {
    id: 21,
    location: "Badvel",
    address:
      "#8-2-258, Mydukuru  Road, Kadapa Dist,Kadapa, Badvel-516227",
    PinCode: "",
    map: "https://maps.app.goo.gl/DCCijZbdJveBHetB6",
    category: "Andhra" ,
    number1: 9364099681,
    number2: "",
    image: [
      {
        id: 1,
        url: Kothakota1,
      },
      {
        id: 2,
        url: Kothakota2,
      },
      {
        id: 3,
        url: Kothakota3,
      },
      {
        id: 4,
        url: Kothakota4,
      },
      {
        id: 5,
        url: Kothakota5,
      },
    ],
  },
  // Harihara
  {
    id: 22,
    location: "Harihara",
    address:
      "DAVANAGERE DIS, HARIHARA NAGAR, OLD P B ROAD,GANDHI GROUND FRONT SIDE HOUSE , NO - 570/A,  Harihara-577601",
    PinCode: "",
    map: "https://maps.app.goo.gl/DCCijZbdJveBHetB6",
    category:"Karnataka",
    number1: 9364099684,
    number2:"",
    image: [
      {
        id: 1,
        url: Kothakota2,
      },
      {
        id: 2,
        url: Kothakota1,
      },
      {
        id: 3,
        url: Kothakota3,
      },
      {
        id: 4,
        url: Kothakota4,
      },
      {
        id: 5,
        url: Kothakota5,
      },
    ],
  },
  // KR Puram, Bangalore
  {
    id: 23,
    location: "KR Puram, Bangalore",
    address:"Building at no 3, Roj Garden 4th Devasandra main road, KR Puram Bangalore North 560036",
    PinCode: "",
    map: "https://maps.app.goo.gl/Aa2tZox7UAkCjZa26",
    category:"Bangalore",
    number1: 9364099692,
    number2:"",
    image: [
      {
        id: 1,
        url: KrPuram1,
      },
      {
        id: 2,
        url: KrPuram2,
      },
      {
        id: 3,
        url: KrPuram3,
      },
      {
        id: 4,
        url: KrPuram4,
      },
      {
        id: 5,
        url: KrPuram5,
      },
    ],
  },
];
